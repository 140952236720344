import {writable, type Readable, type Writable} from "svelte/store";
import {Loadable} from "../loader";
import {cg_support_api, type DefaultApiTypes} from "../api";

export interface SystemInfoResult {
  name: string;
  logs_url: string;
  disable_private_info?: boolean;
  api_docs_url: string;
}

interface ApiTypes extends DefaultApiTypes {
  get_result: SystemInfoResult;
}

export class SystemInfo extends Loadable<SystemInfoResult> {
  #system_info: Writable<SystemInfoResult>;

  constructor() {
    super();

    this.#system_info = writable(default_system_info());
  }

  get system_info(): Readable<SystemInfoResult> {
    return this.#system_info;
  }

  async $load(): Promise<SystemInfoResult> {
    return cg_support_api<ApiTypes>("/api/v1/services/system-info").get();
  }

  $validate(data: SystemInfoResult): boolean {
    return typeof data === "object" && data !== null;
  }

  $set(data: SystemInfoResult): void {
    this.#system_info.set(data);
  }

  $clear(): void {
    this.#system_info.set(default_system_info());
  }
}

function default_system_info(): SystemInfoResult {
  return {
    name: "",
    logs_url: "",
    disable_private_info: false,
    api_docs_url: "",
  };
}
